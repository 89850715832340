@mixin sp() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

.container {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.ant-menu-light .ant-menu-item-selected {
  background-color: transparent;
  color: #0c758d;
}
.ant-menu-light .ant-menu-item {
  height: auto !important;
  line-height: auto !important;

  @include sp {
    padding-left: 0px !important;
  }
}
@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1280px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1368px;
  }
}

@mixin sp() {
  @media screen and (max-width: 767px) {
    @content;
  }
}
.btn-connect {
  border-radius: 12px;
  background: linear-gradient(261.77deg, #55e1a6 -4.72%, #05abc1 107.55%);
  padding: 9px 20px;
  border: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  cursor: pointer;
  width: 172.08px;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px 0px 0px 1.5px rgba(88, 243, 205, 1) inset;

  img {
    width: 20px;
    margin-right: 5px;
  }
}
.gap-10 {
  gap: 10px !important;
}
.gap-5 {
  gap: 5px;
}
.gap-15 {
  gap: 15px !important;
}
.text-white {
  color: #fff !important;
  a {
    color: #fff;
  }
}
.d-ruby {
  display: ruby-text;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.weight-700 {
  font-weight: 700 !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-30 {
  font-size: 30px !important;
}
.text-blue {
  color: rgba(202, 236, 241, 1) !important;
}
.text-lightblue {
  color: rgba(93, 229, 217, 1) !important;
}
.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}
.ant-spin {
  color: #fff !important;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fff !important;
}
.mt-0 {
  margin-top: 0px !important;
}

.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.fsize-16 {
  font-size: 16px;
}

.fsize-18 {
  font-size: 18px;
}

.fsize-20 {
  font-size: 20px;
}

.p-15 {
  padding: 15px !important;
}

.p-10 {
  padding: 10px !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-15 {
  margin-top: 15px !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.error {
  color: red;
}

.full-width {
  width: 100% !important;
}

.border-bot {
  padding-bottom: 7px;
  border-bottom: 0.5px solid #6e6e6e;
}

.weight-400 {
  font-weight: 400 !important;
}

.text-black {
  color: rgba(0, 46, 70, 1) !important;
}
.collapse-cus {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 20px 0px rgba(0, 157, 156, 0.15);
}
.text-gray {
  color: rgba(102, 102, 102, 1);
}

.text-right {
  text-align: right;
}

.p-5-10 {
  padding: 5px 10px !important;
}

.success {
  background: rgba(12, 140, 94, 1) !important;
}
.danger {
  background: rgb(195, 15, 15) !important;
}
.warning {
  background: rgba(255, 168, 0, 1) !important;
}
.text-center {
  text-align: center;
}

.flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-btw-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include sp {
    display: block;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.flex-left {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.flex-right {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.head-title {
  margin-top: 30px;
  margin-bottom: 30px !important;
  font-size: 44px;
  font-weight: 700;
  line-height: 64.8px;
  text-align: center;
  color: rgba(202, 236, 241, 1);
}
.w-20 {
  width: 20px !important;
}
.w-15 {
  width: 15px !important;
}
.ant-divider::after {
  border-color: #fff !important;
}
.ant-divider::before {
  border-color: #fff !important;
}
.faq-page {
  margin-bottom: 50px;
}
.bg-trans {
  background: transparent !important;
}
.header {
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(202, 236, 241, 0.07);
  background: rgb(4 148 166 / 44%);
  padding: 10px 30px;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 99;
  backdrop-filter: blur(10px);

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      .box-img {
        img {
          width: 122px;
        }
      }
    }

    .header-center {
      // max-width: 540px;
      // width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;
      min-width: 700px;

      .header-menu {
        background: transparent !important;
        min-width: 100%;
        justify-content: center;
        border-bottom: none !important;

        .ant-menu-item:hover::after {
          border-bottom: 0px solid transparent;
        }

        .ant-menu-item::after {
          border-bottom: 0px solid transparent;
        }

        .ant-menu-item {
          .ant-menu-title-content {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
          }
        }

        .ant-menu-item-selected::after {
          border-bottom: none !important;
        }
      }

      .search-header {
        width: 100%;
        max-width: 300px;

        .ant-input-affix-wrapper {
          border-radius: 12px !important;
          border: 1px solid var(--BG-White, rgba(255, 255, 255, 0.05)) !important;
          background-color: var(
            --BG-White,
            rgba(255, 255, 255, 0.05)
          ) !important;

          .ant-input-prefix {
            font-size: 20px;
            color: #6f767e;
          }

          .ant-input {
            background-color: transparent !important;
            color: #fff !important;
            padding-left: 10px;

            &::placeholder {
              color: #6f767e;
            }
          }
        }
      }
    }

    .header-right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .round-connect {
        margin-left: 20px;
      }
    }
  }
}

.table-cus {
  .ant-table {
    background: transparent !important;
    .ant-table-row {
      background: rgba(202, 236, 241, 0.07) !important;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell {
      color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(202, 236, 241, 0.1);
      cursor: pointer;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell::before {
      display: none !important;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
    }
    .ant-table-tbody {
      background: rgba(202, 236, 241, 0.07);
    }
    .ant-table-thead {
      th {
        background: rgba(202, 236, 241, 0.2) !important;
        &:hover {
          background: rgba(202, 236, 241, 0.2) !important;
        }
      }
    }
  }
}

.table-cus {
  .ant-table {
    background: transparent !important;
    .ant-table-row {
      background: rgba(202, 236, 241, 0.07) !important;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell {
      color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(202, 236, 241, 0.1);
      cursor: pointer;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell::before {
      display: none !important;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
    }
    .ant-table-tbody {
      background: rgba(202, 236, 241, 0.07);
    }
    .ant-table-thead {
      th {
        background: rgba(202, 236, 241, 0.2) !important;
        &:hover {
          background: rgba(202, 236, 241, 0.2) !important;
        }
      }
    }
  }
}

.table-delegates {
  .ant-table {
    background: transparent !important;
    .ant-table-cell {
      color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(202, 236, 241, 0.1);
      cursor: pointer;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell::before {
      display: none !important;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
    }
    // .ant-table-tbody{
    //   background: rgba(202, 236, 241, 0.07);
    // }
    .ant-table-thead {
      th {
        background: transparent !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(202, 236, 241, 0.2) !important;
        }
      }
    }
  }
}
.pt-15 {
  padding-top: 15px;
}
.border-top {
  border-top: 1px solid rgba(175, 175, 175, 0.5) !important;
}

.table-buy {
  .ant-table {
    background: transparent !important;
    .ant-table-cell {
      color: #000;
      border-bottom: 1px solid rgba(202, 236, 241, 0.1);
      cursor: pointer;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell::before {
      display: none !important;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
    }
    .ant-table-tbody {
      background: rgba(202, 236, 241, 0.2);
    }
    .ant-table-thead {
      background: rgb(0, 149, 181) !important;
      th {
        color: #fff !important;
        background: transparent !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(202, 236, 241, 0.2) !important;
        }
      }
    }
  }
}

.table-projects {
  .ant-table {
    background: transparent !important;
    // .ant-table-row{
    //   background: rgba(202, 236, 241, 0.07) !important;
    //   &:hover {
    //     background: rgba(202, 236, 241, 0.07) !important;
    //   }
    // }
    .ant-table-cell {
      color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(202, 236, 241, 0.1);
      cursor: pointer;
      &:hover {
        background: rgba(202, 236, 241, 0.07) !important;
      }
    }
    .ant-table-cell::before {
      display: none !important;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
    }
    // .ant-table-tbody{
    //   background: rgba(202, 236, 241, 0.07);
    // }
    .ant-table-thead {
      th {
        background: rgba(32, 255, 254, 0.05) !important;
        border-bottom: 1px solid rgba(32, 255, 254, 1);
        // border-top: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(202, 236, 241, 0.2) !important;
        }
      }
    }
  }
  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 8px;
    border-bottom-left-radius: 8px !important;
  }
  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 8px;
    border-bottom-right-radius: 8px !important;
  }
}

.mt-50 {
  margin-top: 50px !important;
}
.mt-30-mb {
  margin-top: 30px;

  @include sp {
    margin-top: 0px;
  }
}
.nodes-sale {
  margin-top: 0px;
  // background: #6faae827;
  border-radius: 15px;
  min-height: 400px;
  padding: 30px;
  padding-top: 0px !important;
  @include sp {
    padding: 15px;
  }

  .node-banner {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 20px 0px rgba(0, 157, 156, 0.15);
    border-radius: 15px;
    padding: 15px;
    @include sp {
      margin-bottom: 15px;
    }

    .banner-info {
      // background: rgba(255, 255, 255, 0.1);
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      // padding-top: 30px;
      // padding-bottom: 30px;
      // margin-bottom: 15px;
      border-radius: 15px;
      text-align: center;
      img {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .node-detail {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 20px 0px rgba(0, 157, 156, 0.15);
    border-radius: 15px;
    padding: 15px;
  }
  .node-banner-bottom {
    margin: auto;
    padding: 15px 25px;
    background: rgba(65, 209, 212, 0.2);
    border-radius: 0px 0px 10px 10px;
    color: #48ffec;
    width: fit-content;
  }
}
.border-none {
  border: none !important;
}
.daily-limit {
  background: rgba(244, 244, 244, 1);
  padding: 10px 15px;
  text-align: center;
  color: rgb(147, 147, 147);
  border-radius: 5px;
}
.social {
  margin-top: 25px;
  padding-top: 30px;
  border-top: 1px solid #dadada;
  @include sp {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  a {
    color: rgba(68, 68, 68, 1) !important;
  }
}
.w-20 {
  width: 20px !important;
}
.mt-10-sm {
  margin-top: 0px;
  @include sp {
    margin-top: 15px;
  }
}
.nodes {
  margin-top: 50px;
  padding: 0px 0px;

  @include sp {
    margin-top: 30px;
  }

  .nodes-list {
    // text-align: center;

    .border-active {
      // border: 2px solid rgba(20, 224, 136, 0.771) !important;
      background: rgba(56, 217, 209, 0.186) !important;
    }

    .border-soldout {
      background: rgb(90 192 202 / 46%) !important;
    }

    .nodes-item {
      border: 1px solid rgba(52, 200, 210, 1);
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
      margin-bottom: 25px;
      cursor: pointer;
      min-height: 383px;

      &:hover {
        background: rgb(56 217 210 / 33%) !important;
      }

      .node-top {
        padding: 25px;
        @include sp {
          padding: 15px;
        }
        .node-title {
          font-weight: 600;
          line-height: normal;
          font-size: 18px;
          color: #ffffff;
          margin-bottom: 30px;
        }
        .nodes-info {
          font-size: 16px !important;
          margin-bottom: 30px;
        }
      }
      .node-bottom {
        padding: 15px 25px;
        border-top: 1px solid #909090;
        @include sp {
          padding: 15px;
        }
      }

      .no-available {
        border: 2px solid #000;
        padding: 10px 25px;
        color: rgb(217, 20, 20);
        font-size: 20px;
        font-weight: bold;
        width: fit-content;
        position: absolute;
        margin-top: 130px;
        background: rgba(255, 255, 255, 0.623);
        z-index: 999;
        margin-left: 50px;
        transform: rotate(-20deg);
      }
    }

    img {
      max-width: 100%;
      width: auto;
    }
  }
}

.node-progress {
  .ant-progress {
    margin-bottom: 5px;

    .ant-progress-inner {
      height: 10px !important;
      .ant-progress-bg {
        height: 10px !important;
      }
    }
  }
}

.partner {
  margin-top: 50px;
  margin-bottom: 100px;

  .partner-title {
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: rgba(255, 255, 255, 1);

    @include sp {
      font-size: 30px;
      line-height: normal;
    }
  }
  .partner-list {
    margin-top: 40px;
    text-align: center;
  }
}
.ant-pagination .ant-pagination-item {
  border: 1px solid #fff;
  a {
    color: #fff;
  }
}
.ant-pagination .ant-pagination-item-active {
  a {
    color: #000 !important;
  }
}
.mlr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
// .symbiosis{
//   margin-top: 100px;
//   margin-bottom: 100px;
//   @include sp{
//     padding: 15px;
//   }
//   .symbiosis-title{
//     font-size: 46px;
//     font-weight: 700;
//     line-height: 55.2px;
//     text-align: left;
//     color: rgba(202, 236, 241, 1);
//     @include sp {
//       font-size: 30px;
//       line-height: normal;
//     }
//   }
//   .symbiosis-desc{
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 20.4px;
//     text-align: left;
//     color: rgba(202, 236, 241, 1);
//     margin-top: 15px;
//     margin-bottom: 15px;
//   }
//   .symbiosis-item{
//     border: 1px solid rgba(13, 186, 136, 0.4);
//     border-radius: 15px;
//     padding: 15px;
//     text-align: center;

//     .item-img{
//       background: rgba(4, 5, 10, 1);
//       margin: auto;
//       text-align: center;
//       img{
//         width: auto;
//         max-width: 100%;
//         display: inline;
//       }
//     }
//     .item-title{
//       font-size: 20px;
//       font-weight: 700;
//       line-height: 17.73px;
//       text-align: center;
//       color: rgba(255, 255, 255, 1);
//     }
//     .item-net{
//       margin-top: 15px;
//       font-size: 20px;
//       font-weight: 700;
//       line-height: 17.73px;
//       text-align: center;
//       color: rgba(229, 231, 235, 0.6);
//     }
//   }
// }
.btn-gray {
  padding: 10px 20px;
  border: 1px solid rgba(166, 216, 221, 0.08);
  background: rgba(166, 216, 221, 0.12);
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: rgba(247, 247, 248, 1);
  cursor: pointer;
}
.link-a {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: rgba(247, 247, 248, 1) !important;
  text-decoration: auto;

  &:hover {
    text-decoration: underline !important;
  }
}
.text-info {
  color: rgb(6 199 255) !important;
}
.text-decoration {
  text-decoration: underline !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px !important;
}
.font-40 {
  font-size: 40px !important;
}
.text-gray-light {
  color: rgba(229, 231, 235, 0.6) !important;
}
.statistic-container {
  background: rgba(202, 236, 241, 0.07);
  padding: 15px;
  border-radius: 15px;
}
.text-main {
  color: rgba(247, 247, 248, 1);
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-5-mb {
  margin-top: 5px;
  @include sp {
    margin-top: 15px;
  }
}
.text-underline {
  text-decoration: underline;
}
.btn-lightblue {
  background: linear-gradient(
    90deg,
    #12c781 -441.83%,
    #63e9da -98.47%,
    #09a9cb 244.9%
  );
  border-radius: 40px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 15px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #12c781 -441.83%,
      #44c1b2 -98.47%,
      #0c758d 244.9%
    );
  }
}
.bg-blue-gradiant {
  background-color: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    #20fffe 100%
  ) !important;
}
.border-radius-30 {
  border-radius: 30px !important;
}
.btn-blue-border {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #20fffe 100%);
  border-radius: 12px;
  border: 1px solid #1ec6c6;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #12c781 -441.83%,
      #44c1b2 -98.47%,
      #0c758d 244.9%
    );
  }
}
.btn-blue-tran {
  background: transparent;
  border-radius: 12px;
  border: 1px solid #1ec6c6;
  color: #1ec6c6;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #12c781 -441.83%,
      #44c1b2 -98.47%,
      #0c758d 244.9%
    );
  }
}

.btn-white-border {
  background: transparent;
  border-radius: 12px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #12c781 -441.83%,
      #44c1b2 -98.47%,
      #0c758d 244.9%
    );
  }
}

.btn-blue-light {
  background: linear-gradient(90deg, #139998 0%, #20fffe 100%);
  border-radius: 12px;
  border: 1px solid #1ec6c6;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(90deg, #1dd6d6 0%, #19bfbf 100%);
  }
}
.pb-30 {
  padding-bottom: 30px;
}
.ul-faq {
  li {
    margin-bottom: 7px;
    margin-top: 7px;
  }
}
.btn-border-blue {
  background-image: url(../../public/images/btn-border.png) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-color: transparent !important;
  border-radius: 12px;
  border: none !important;
  color: #fff;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 10px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    opacity: 0.8;
  }
}
.table-row-light {
  background-color: rgba(32, 255, 254, 0.05) !important;
}
.table-row-dark {
  background-color: transparent !important;
}
.btn-blue {
  background: rgba(32, 255, 254, 0.1);
  border-radius: 12px;
  border: none;
  color: rgba(32, 255, 254, 1);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #12c781 -441.83%,
      #44c1b2 -98.47%,
      #0c758d 244.9%
    );
  }
}
.btn-yellow {
  background: rgba(255, 236, 32, 0.1);
  border-radius: 12px;
  border: none !important;
  color: rgb(255, 236, 32);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #acc712 -441.83%,
      #acc144 -98.47%,
      #6d8d0c 244.9%
    );
  }
}
.network-circle {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 5px 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.grp-input {
  .ant-input-wrapper {
    background: rgba(0, 149, 181, 1) !important;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
    color: rgba(32, 255, 254, 1) !important;
    border-radius: 100px;
    padding: 2px 5px;

    .ant-input-group-addon {
      background: transparent;
      border: none !important;
      width: 30px;
      padding: 2px;
    }
    .ant-input {
      height: 32px;
      background: transparent !important;
      border: none !important;
      color: rgba(32, 255, 254, 1) !important;
      box-shadow: none !important;
      font-size: 16px !important;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center !important;
    }
  }
  .btn-increase {
    border: none !important;
    font-size: 20px;
    height: 30px;
    font-weight: 600;
    width: 30px;
    line-height: normal;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.5);
    color: #000 !important;
    border: none !important;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}
.border-black {
  border: 5px solid rgba(0, 0, 0, 0.2) !important;
}
.check-tern {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(0, 149, 181, 1);
    border-color: rgba(0, 149, 181, 1);
  }
}
.grp-input-discount {
  .ant-input-wrapper {
    background: rgba(255, 255, 255, 0.2) !important;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
    color: #fff !important;
    border-radius: 100px;
    padding: 0px;

    .ant-input-group-addon {
      background: transparent;
      border: none !important;
      width: 30px;
      padding: 0px;
    }
    .ant-input {
      height: 35px;
      background: transparent !important;
      border: none !important;
      color: #fff !important;
      box-shadow: none !important;
      font-size: 16px !important;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center !important;
    }
  }
  input::placeholder {
    /* Most modern browsers support this now. */
    color: rgb(216, 216, 216) !important;
  }
}
.ref-social-input {
  .ant-input-wrapper {
    background: rgba(244, 244, 244, 1) !important;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
    color: rgba(175, 175, 175, 1) !important;
    border-radius: 10px;
    padding: 0px;

    .ant-input-group-addon {
      background: transparent;
      border: none !important;
      width: 30px;
      padding: 0px;
    }
    .ant-input {
      height: 35px;
      background: transparent !important;
      border: none !important;
      color: rgb(130, 130, 130) !important;
      box-shadow: none !important;
      font-size: 16px !important;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center !important;
    }
  }
  input::placeholder {
    /* Most modern browsers support this now. */
    color: rgb(216, 216, 216) !important;
  }
}
.text-font-gray {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.85px;
}
.text-font-white {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.5px;
}
.text-center {
  text-align: center;
}
.text-center-mb {
  text-align: left;

  @include sp {
    text-align: center;
  }
}
.text-rightcenter-mb {
  text-align: right;

  @include sp {
    text-align: center;
  }
}
.footer-social {
  @include sp {
    display: flex;
    justify-content: center;
  }
  ul {
    display: flex;
    gap: 15px;
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.text-right {
  text-align: right;
}
.footer-menu {
  ul {
    justify-content: end;
  }
}

.mt-10 {
  margin-top: 10px;
}
.d-flex-left {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
}
.d-flex-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.d-flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
}

.d-flex-center {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.d-flex-center-mobile {
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  @include sp {
    display: block;
    text-align: center;
  }
}
.d-flex-mid-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @include sp {
    display: grid;
  }
}
.d-flex-left-mobile {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;

  @include sp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.mt-15-sp {
  margin-top: 0px;

  @include sp {
    margin-top: 15px;
  }
}
.d-flex-mid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-left {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.d-flex-end {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
.ant-radio-wrapper {
  span {
    color: #fff !important;
  }
}
.modal-cus {
  .ant-modal-content {
    background: rgba(10, 13, 18, 1);
    border: 0.9px solid rgba(70, 170, 117, 1);
    padding: 15px;
  }
  .ant-modal-header {
    background: transparent !important;
  }
}
.modal-node-success {
  width: 100% !important;
  max-width: 320px !important;
}
.header-bg-node {
  background: rgb(0 227 255 / 15%) !important;
  backdrop-filter: blur(10px);
}
.modal-salenode {
  width: 100% !important;
  max-width: 700px !important;
}
.m-auto {
  margin: auto;
}
.modal-mint {
  .ant-modal-content {
    background: rgba(0, 0, 0, 1) !important;
    border: 1px solid rgba(97, 97, 97, 1) !important;
    padding: 15px;
  }
  .ant-modal-header {
    background: transparent !important;
  }
}
.text-right {
  text-align: right;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent !important;
  color: #fff;
}
.ant-table-cell .ant-empty-description {
  color: #fff !important;
}
.tab-over {
  overflow-x: auto;
}
.border-bottom-10 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 10px;
}
.input-blue {
  background: rgba(32, 255, 254, 0.1);
  border: 1px solid rgba(32, 255, 254, 0.3);
  height: 50px;
  color: rgba(32, 255, 254, 1);

  &:hover {
    background: rgba(32, 255, 254, 0.1);
  }
  &:focus-within {
    background: rgba(32, 255, 254, 0.1) !important;
  }
}
.flex-left {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}
.modal-connect {
  .connect-des {
    background: rgba(0, 46, 70, 1);
    color: #bdbdbd;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .ant-modal-header {
    .ant-modal-title {
      color: rgba(255, 255, 255, 1);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .ant-modal-content {
    border-radius: 12px;
    // background: #fff;

    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
  }

  .ant-modal-body {
    border-radius: 12px;
    // background: #fff;

    .modal-content-connect {
      padding: 15px 0px;
      padding-bottom: 20px;
      display: block;
      border-radius: 12px;
      gap: 16px;

      .btn-item-connect {
        background: rgba(10, 13, 18, 1);
        width: 100%;
        border: 1px solid rgba(74, 222, 128, 0.4);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 7px;
        box-shadow: 0px 6px 8px 0px rgba(24, 226, 153, 0.12);
        margin-bottom: 10px;

        img.wl-ic {
          width: 40px;
          margin-right: 20px;
        }

        .name-chain-wl {
          color: #fff;
          text-align: center;
          font-size: 16px;
          display: block;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: left;
        }
      }
      .btn-item-connect:hover {
        border: 1px solid rgba(206, 238, 218, 0.4) !important;
      }
    }
  }
}
.footer-main {
  padding-top: 20px;
  padding-bottom: 20px;
  // background: transparent;
  background: radial-gradient(
    91.21% 91.21% at 47.84% 99.8%,
    #202128 0%,
    #1d232d 11.98%,
    #132c40 63.77%,
    #102f47 100%
  );
  border-top: 1px solid rgba(202, 236, 241, 0.1);
}

.mt-10-mobile {
  margin-top: 0px;

  @include sp {
    margin-top: 10px;
  }
}

.mr-5 {
  margin-right: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.font-16 {
  font-size: 16px !important;
}
.font-25 {
  font-size: 25px;
}
.font-40 {
  font-size: 40px;
}

.app-page-node {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(94, 228, 216, 0.6) 0%,
    rgba(72, 213, 212, 0.35) 25.5%,
    rgba(57, 202, 210, 0.2) 58%,
    rgba(54, 200, 209, 0.141683) 76%,
    rgba(52, 198, 209, 0) 99%
  ) !important;
}

.app-page {
  background-image: url(../../public/images/bg-main.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding-top: 150px;
  @include sp {
    padding-top: 70px;
  }

  .ant-content-cus {
    background: rgba(255, 255, 255, 1);
    border-top-left-radius: 20px;
    @include sp {
      border-radius: 0px;
    }
  }

  .app-hearder {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    background: transparent;
  }
}
.app-page-default {
  background-image: url(../../public/images/home-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding-top: 120px;
  @include sp {
    padding-top: 100px;
    background-size: cover;
  }

  .ant-content-cus {
    background: rgba(255, 255, 255, 1);
    border-top-left-radius: 20px;
    @include sp {
      border-radius: 0px;
    }
  }

  .app-hearder {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    background: transparent;
  }
}
.home-default {
  max-width: 1000px;
  margin: auto;

  padding-bottom: 50px;

  @include sp {
    margin-top: 0px;
    max-width: 100%;
  }
}
.select-fiter {
  .ant-select {
    // background: rgba(0, 0, 0, 0.05);
    // border: 1px solid rgba(255, 255, 255, 0.1);
    .ant-select-selector {
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.33);
    }
    .ant-select-selection-item {
      color: #fff;
      font-weight: 500;

      @include sp {
        color: #000;
      }
    }
    .ant-select-arrow {
      color: #fff;
      font-weight: 500;
      @include sp {
        color: #000;
      }
    }
  }
}
.select-node {
  .ant-select-selector {
    background: rgba(32, 255, 254, 0.1) !important;
    border: 1px solid rgba(9, 169, 203, 1) !important;
    box-shadow: 2px 2px 4px 0px rgba(9, 169, 203, 0.1);
    color: rgba(32, 255, 254, 1);
    .ant-select-selection-item {
      color: rgba(32, 255, 254, 1);
    }
  }
  .ant-select-arrow {
    color: rgba(32, 255, 254, 1);
  }
}
.tabs-custome {
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 5px;
      // max-width: 315px;
      // margin: auto;
      border-radius: 10px;
      padding-bottom: 0px;

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          padding-top: 5px !important;
          margin-left: 0px !important;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          // width: 100%;

          .ant-tabs-tab-btn {
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            padding: 8px 15px;
            color: rgba(202, 236, 241, 0.7);
            width: 100%;
          }
        }

        .ant-tabs-tab:hover {
          background: rgba(255, 255, 255, 0.2);
          font-size: 16px;
          font-weight: 400;
          color: #fff !important;
          .ant-tabs-tab-btn {
          }
        }

        .ant-tabs-tab-active {
          // background: rgba(12, 140, 94, 1);
          background: rgba(255, 255, 255, 0.2);
          font-size: 16px;
          font-weight: 400;
          color: #fff !important;
          border-bottom: 4px solid rgba(102, 246, 255, 1);

          .ant-tabs-tab-btn {
            border-radius: 8px;
            // background: var(--BG-White, rgba(255, 255, 255, 0.05));
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            padding: 8px 15px;
            color: rgba(202, 236, 241, 1);
            padding: 8px 15px;
            border-bottom: none;
          }
        }

        .ant-tabs-ink-bar {
          background: transparent !important;
        }
      }
    }
  }
}
.text-uppercase {
  text-transform: uppercase;
}
.tabs-delegate {
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 5px;
      border-radius: 0px;
      padding-bottom: 0px;

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          padding-top: 5px !important;
          margin-left: 0px !important;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          // width: 100%;

          .ant-tabs-tab-btn {
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            padding: 8px 15px;
            color: rgba(202, 236, 241, 0.7);
            width: 100%;
          }
        }

        .ant-tabs-tab:hover {
          background: rgba(255, 255, 255, 0.2);
          font-size: 16px;
          font-weight: 400;
          color: #fff !important;
          .ant-tabs-tab-btn {
          }
        }

        .ant-tabs-tab-active {
          // background: rgba(12, 140, 94, 1);
          background: transparent;
          font-size: 16px;
          font-weight: 400;
          color: #fff !important;
          border-bottom: 4px solid rgba(102, 246, 255, 1);

          .ant-tabs-tab-btn {
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            padding: 8px 15px;
            color: rgba(202, 236, 241, 1);
            padding: 8px 15px;
            border-bottom: none;
          }
        }

        .ant-tabs-ink-bar {
          background: transparent !important;
        }
      }
    }
  }
}

.ant-menu-item-selected {
  .ant-menu-title-content {
    color: rgb(255 255 255) !important;
  }
}

.time-count {
  width: fit-content;
  margin: auto;
  color: rgba(229, 231, 235, 0.6);
}
.time-count {
  .time-item {
    letter-spacing: 0.5px;
    color: #fff;
    background-color: rgba(170, 170, 170, 0.372);
    padding: 5px;
    border-radius: 3px;
    font-size: 16px;
  }
}
.max-ref {
  max-width: 700px;
  margin: auto;
  @include sp {
    max-width: unset;
  }
}
.w-full {
  width: 100% !important;
}
.imgnode-full {
  width: 100% !important;
  max-width: 320px;
  margin: auto;
  border-radius: 10px;
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.w-fitcontent {
  width: fit-content !important;
}
.bg-white {
  background: #fff !important;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item::after {
  border-bottom: none !important;
}

.btn-green {
  background: rgba(12, 140, 94, 1) !important;
  border: none;
  padding: 10px 15px;
  line-height: normal;
  color: #fff !important;
  font-size: 16px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;

  &:hover {
    background: rgba(48, 202, 187, 0.8) !important;
    color: #fff !important;
  }
  &:disabled {
    background: rgba(12, 140, 94, 0.4) !important;
    opacity: 0.5;
    cursor: no-drop;
  }
}
.p-12-15 {
  padding: 12px 15px !important;
}
.p-10-15 {
  padding: 10px 15px !important;
}

.btn-wrong {
  border: 1px solid rgb(161 66 66);
  color: rgb(215, 16, 16);
  background: transparent !important;
  font-size: 16px;
  padding: 7px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.text-danger {
  color: rgb(215, 16, 16) !important;
  .ant-alert-description {
    color: rgb(215, 16, 16) !important;
  }
}
.bg-danger {
  background: rgb(215, 16, 16) !important;
}
.text-success {
  color: rgb(25, 226, 102) !important;
}
.btn-danger {
  &:disabled {
    color: #fff !important;
    cursor: no-drop;
    opacity: 0.7;
  }
}
.icon-green {
  border: 1px solid rgba(13, 186, 136, 0.4);
  border-radius: 12px;
  padding: 10px;
  width: fit-content;
  margin: auto;
  margin-bottom: 15px;
  background: rgba(166, 216, 221, 0.1);

  span {
    background: rgba(24, 226, 153, 1);
    font-size: 18px;
    border-radius: 8px;
    padding: 5px;
  }
}
.bg-blue {
  background: rgba(32, 255, 254, 0.1);
}
.p-5 {
  padding: 5px;
}
.border-5 {
  border-radius: 5px;
}
.text-blue {
  color: rgba(32, 255, 254, 1) !important;
}
.icon-red {
  border: 1px solid rgba(255, 101, 101, 0.4) !important;
  border-radius: 12px;
  padding: 10px;
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  background: rgba(255, 101, 101, 0.1) !important;

  span {
    background: rgba(255, 101, 101, 1) !important;
    font-size: 18px;
    border-radius: 8px;
    padding: 5px;
  }
}

.height-auto {
  height: auto !important;
  min-height: auto !important;
}
.mt-0-15-mb {
  margin-top: 0px;
  @include sp {
    margin-top: 15px;
  }
}
.nft-item {
  margin-top: 15px;
}
.mt-50-mb {
  margin-top: 50px;
  @include sp {
    margin-top: 15px;
  }
}
.d-desktop {
  display: block;
  @include sp {
    display: none !important;
  }
}
.d-mobile {
  display: none;
  @include sp {
    display: block !important;
  }
}
.content-center {
  justify-content: center !important;
}
.ant-modal-close {
  color: #fff !important;
}
.partner-item {
  width: auto !important;
  padding: 25px;
  text-align: center;
  height: 90px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  img {
    width: auto;
    max-width: 100%;
    border-radius: 10px;
    height: 100%;
    border: 1px solid #66f6ff;

    @include sp {
      max-width: 300px;
      margin: auto;
      height: auto;
      max-height: 100%;
    }
  }
}
.profile-banner {
  margin-top: 70px;
  border-bottom: 5px solid #66f6ff;
  .profile-img {
    img {
      width: 100%;
    }
  }
}
.profile-content {
  margin-bottom: 80px;
  .mynode-item {
    cursor: pointer;
    margin-bottom: 20px;
    .item-img {
      // padding-top: 25px;
      // padding-bottom: 15px;
      // background: rgba(249, 249, 249, 1);
      border-radius: 5px;
      text-align: center;
      margin-bottom: 10px;
      img {
        width: auto;
        max-width: 100%;
        border-radius: 5px;
      }
    }
    .item-name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .ref-content {
    margin-top: 20px;
    .ref-title {
      font-weight: 500;
      font-size: 22px;
      line-height: normal;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .ref-description {
      font-weight: 300;
      font-size: 16px;
      line-height: normal;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .ref-total {
      margin-top: 30px;
      .total-item {
        border-radius: 5px;
        margin-bottom: 15px;
        text-align: center;
        padding: 20px;
      }
    }
    .ref-withdraw-money {
      margin-top: 20px;
      padding: 20px;
      background: radial-gradient(
        99.29% 804.91% at 12.89% 50%,
        #1b4e5e 0%,
        #50c4c7 30.5%,
        #68eaed 38.64%,
        #ffffff 100%
      );
      border-radius: 10px;
      margin-bottom: 20px;
    }

    .ref-history-table {
      border-top: 1px solid rgba(34, 229, 255, 1);
      margin-bottom: 30px;
      padding-top: 20px;
    }
  }
}
.height-full {
  height: 100vh;
}
.bg-lightgray {
  background: rgba(255, 255, 255, 0.4);
}
.bg-normalgray {
  background: rgba(255, 255, 255, 0.3);
}
.m-auto {
  margin: auto;
}
.bg-darkgray {
  background: rgba(255, 255, 255, 0.2);
}
.text-lightblue {
  color: rgba(7, 185, 197, 1);
}
.text-darkblue {
  color: rgba(83, 201, 252, 1);
}
.text-darkblue-500 {
  color: rgba(0, 149, 181, 1) !important;
}
.text-lightgray {
  color: rgba(255, 255, 255, 0.6);
}
.ant-progress.ant-progress-status-success .ant-progress-bg {
  background-color: #1677ff !important;
}
.btn-grid {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: transparent;
  border: 0px;
  color: #fff;
  cursor: default;
  transition: background-color 0.3s ease-in-out 0s;

  &:hover {
    cursor: pointer;
    background-color: rgba(170, 170, 170, 0.372);
  }
}
.border-line {
  border: 1px solid rgba(155, 155, 155, 0.362);
  border-radius: 5px;
  padding: 3px;
}
.btn-grid-active {
  background-color: rgba(170, 170, 170, 0.372);
}
.grid-item {
  border: 1px solid rgba(155, 155, 155, 0.362);
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
  cursor: pointer;
}
.text-green {
  color: #3bd8ae;
}
.img-full {
  width: auto;
  max-width: 100%;
  margin: auto;
}
.img-shadow {
  max-width: 550px;
  margin-left: -120px;
  img {
    width: 100%;
  }
  @include sp {
    display: none;
  }
}
.text-linegradiant {
  background: linear-gradient(90deg, #008989 0%, #20fffe 100%);

  font-size: 35px;
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;

  @include sp {
    font-size: 30px;
  }
}
.home-des {
  margin-top: -30px;
  text-align: center;

  @include sp {
    margin-top: 15px;
  }
}
.social-border {
  // border: 1px solid rgba(32, 255, 254, 0.2);
  padding: 20px;
  border-radius: 10px;

  .social-item {
    border-radius: 10px;
    border: 1px solid rgba(32, 255, 254, 0.2);
    padding: 15px;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0.1);
  }
}
.delegate-page {
  margin-bottom: 50px;
  .delegate-banner {
    background-image: url(../../public/images/dele-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-height: 255px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.border-radius-10 {
  border-radius: 10px;
}
.delegate-total {
  background: rgba(32, 255, 254, 0.05);
  border-radius: 12px;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 255, 254, 1);
}
.border-bottom-blue {
  border-bottom: 1px solid rgba(32, 255, 254, 1);
}
.pagiation-custom {
  .ant-pagination-next {
    button {
      color: rgba(32, 255, 254, 1) !important;
    }
  }
  .ant-pagination-prev {
    button {
      color: rgba(32, 255, 254, 1) !important;
    }
  }
}

.active-status {
  background: rgba(18, 199, 129, 0.1);
  border-radius: 10px;
  padding: 5px 20px;
  color: rgba(18, 199, 129, 1);
  width: fit-content;
}
.deactive-status {
  background: rgba(32, 255, 254, 0.1);
  border-radius: 10px;
  padding: 5px 20px;
  color: rgba(175, 175, 175, 1);
  width: fit-content;
}
.margin-auto {
  margin: auto;
}
.reward-delegate {
  margin-top: 30px;
  padding: 20px;
  padding-top: 0px !important;
  border-radius: 20px;
  min-height: 50px;
  border-bottom: 1px solid rgba(32, 255, 254, 1);
  background: linear-gradient(
    180deg,
    rgba(32, 255, 254, 0.32) -62.23%,
    rgba(0, 0, 0, 0.2) 171.81%
  );

  @include sp {
    padding: 14px;
  }
}
.airdrop-page {
  .airdrop-block {
    background: rgba(32, 255, 254, 0.05);
    border-radius: 12px;
    padding: 15px;
    max-width: 900px;
    margin: auto;
    @include sp {
      max-width: unset;
    }

    .claim-status {
      width: fit-content;
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
    }
    .claim-success {
      background: rgba(18, 199, 129, 0.1);
      color: rgba(18, 199, 129, 1);
    }
    .claim-pending {
      background: rgb(199 51 18 / 29%);
      color: rgb(255 73 28);
    }
  }
}
